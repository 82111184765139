import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"

const StyledParagraph = styled.article`
  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

function Paragraph({ paragraph_title, paragraph_content, children, ...props }) {
  return (
    <>
      <StyledParagraph>
        <Content content={paragraph_title} />
        <Content content={paragraph_content} />
        {children}
      </StyledParagraph>
    </>
  )
}

export default Paragraph

Paragraph.defaultProps = {}
Paragraph.propTypes = {
  children: PropTypes.node,
}
