import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Content from "components/Content"
import Person from "./"
import Container from "components/Container"
import CTALink from "components/CTA/Link"
import ResponsiveImage from "components/ResponsiveImage"
import { mapPersonCTAs } from "./helper"

const StyledPersonCTA = styled.div`
  padding: 2rem 4rem 4rem;

  .person {
    display: grid;
    grid-template-columns: 12rem 1fr;
    grid-column-gap: 2rem;
    align-items: center;

    .person__info {
      .name {
        margin: 0;
      }

      .role {
        margin-top: 0;
        font-size: 1.4rem;
      }

      .work {
        margin-top: 1rem;
        font-size: 1.4rem;
        line-height: 1.3;
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
      margin: 0 auto;
      display: grid;
      grid-template-columns: 24rem 1fr;
      grid-column-gap: 4rem;

      .person {
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .person__info {
          margin-top: 2rem;
          text-align: center;

          .role {
            font-size: 1.8rem;
          }

          .work {
            margin-top: 2rem;
            font-size: 1.8rem;
          }
        }
      }
  `}
`

const StyledImage = styled.div`
  border-radius: 50%;
  width: 16rem;
  height: 16rem;
  overflow: hidden;

  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 0 auto;
    width: 24rem;
    height: 24rem;
  `}
`

function PersonCTA({
  person,
  ctas,
  person_cta_content,
  person_cta_title,
  ...props
}) {
  const person_ctas = mapPersonCTAs(person, ctas)

  return (
    <Container>
      <StyledPersonCTA>
        <div className="person">
          <StyledImage>
            <ResponsiveImage img={person.imageSharp || person.image} />
          </StyledImage>
          <div className="person__info">
            {person.first_name && (
              <h4 className="name">{`${person.first_name} ${person.last_name}`}</h4>
            )}
            {person.role && <p className="role">{`${person.role}`}</p>}
            {person.areas_of_work && (
              <p className="work">{`${person.areas_of_work}`}</p>
            )}
          </div>
        </div>
        <div className="person__content">
          <Content content={person_cta_title} />
          <Content content={person_cta_content} />
          {person_ctas.map((cta, i) => {
            return (
              <CTALink
                key={`cta_${i}`}
                primary={cta.primary}
                bg={cta.primary ? "secondary" : null}
                border={cta.primary ? "secondary" : null}
                blank
                to={cta.value}
              >
                {cta.text}
              </CTALink>
            )
          })}
        </div>
      </StyledPersonCTA>
    </Container>
  )
}

export default PersonCTA

PersonCTA.defaultProps = {}
PersonCTA.propTypes = {}
