import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledLabel = styled.span`
  display: inline-block;
  background: ${(props) => props.theme.colors.kelp};
  color: ${(props) => props.theme.colors.white};
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-size: 1.7rem;
  letter-spacing: 0.1rem;
`

function Label({ children, ...props }) {
  return <StyledLabel {...props}>{children}</StyledLabel>
}

export default Label

Label.defaultProps = {}
Label.propTypes = {
  children: PropTypes.node.isRequired,
}
