import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Elements } from "prismic-reactjs"
import { default as GridParagraph } from "@mostlycode/ui/dist/Paragraphs/GridParagraph"
import Content from "components/Content"
import CTARepeater from "components/CTA/Repeater"
import ResponsiveImage from "components/ResponsiveImage"
import Label from "components/Label"
import Container from "components/Container"
import {
  bgBrandShape,
  bgBrandShapeMobile,
  clipImage,
  clipImageMobile,
} from "components/Shape"

const StyledHeroSection = styled.div`
  position: relative;
  display: grid;
  overflow: hidden;

  ${(props) => props.theme.media.tablet_landscape_up`
    min-height: 50rem;
    max-height: 70rem;
    display: flex;
    align-items: center;
    padding: 8rem 0;
  `}
`

const StyledImageWrapper = styled.div`
  grid-area: image;
  width: 100%;
`

const StyledResponsiveImage = styled(ResponsiveImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;

  ${(props) => props.theme.media.tablet_portrait_up`

  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    // height: auto;
  `}
`

const StyledContent = styled.article`
  z-index: 20;
  grid-area: paragraph;
  padding: 2rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;

    border-radius: 1rem 0 0 1rem;

    .inner {
      max-width: 60rem;
      position: relative;
      z-index: 10000;
      align-self: center;
    }
  `}
`

const StyledGridParagraph = styled(GridParagraph)`
  align-items: center;
`

const htmlSerializer = (type, element, content, children) => {
  // Refer to this page for a full elements reference:
  // https://prismic.io/docs/javascript/beyond-the-api/html-serializer#7_0-example-with-all-elements
  switch (type) {
    case Elements.heading1:
      return <h1>{children}</h1>
    default:
      return null
  }
}

const gridPhone = `"paragraph" "image"`
const gridTabletPortrait = `"paragraph paragraph paragraph paragraph paragraph . image image image image image"`

function HeroSection({ isHome, ctas, ...props }) {
  const { content, headline, image, pre_headline } = props
  return (
    <StyledHeroSection>
      <Container>
        <StyledGridParagraph
          gridPhone={gridPhone}
          gridTabletPortrait={gridTabletPortrait}
          useFractions
        >
          <StyledContent>
            <div className="inner">
              <Label>{pre_headline}</Label>
              <Content content={headline} serializer={htmlSerializer} />
              <Content content={content} serializer={htmlSerializer} />
              <CTARepeater items={ctas} />
            </div>
          </StyledContent>
          {image && (
            <StyledImageWrapper>
              <StyledResponsiveImage img={image} />
            </StyledImageWrapper>
          )}
        </StyledGridParagraph>
      </Container>
    </StyledHeroSection>
  )
}

export default HeroSection

HeroSection.defaultProps = {
  isHome: false,
}

HeroSection.propTypes = {
  isHome: PropTypes.bool,
  children: PropTypes.node,
}
