import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import { Icon } from "@mostlycode/ui"
import DefaultCard from "@mostlycode/ui/dist/Card"
import ResponsiveImage from "components/ResponsiveImage"
import Product from "components/Product"
import { linkResolver } from "utils/linkResolver"

const StyledCard = styled(DefaultCard)`
  position: relative;
  padding: 0;
  box-shadow: 0px 5px 20px -1px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  overflow: hidden;

  &.-link {
    /* Apply styling to hover for cards that have a link to another page */

    .bg-img img {
    }

    &:hover {
      .bg-img img {
      }
    }
  }

  .bg-img {
    position: relative;
    object-fit: cover;
    z-index: 25;
    overflow: hidden;

    &:before {
      position: absolute;
      background: ${(props) => props.theme.colors.kelp};
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      opacity: 0.2;
    }
  }

  .content {
    z-index: 50;
    position: absolute;
    text-align: left;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    color: ${(props) => props.theme.colors.white};
    font-size: 2.4rem;
    // font-weight: bold;
    padding: 2rem;
    background: ${(props) => props.theme.colors.kelp};

    .section {
      text-transform: uppercase;
    }

    .read-more {
      display: flex;
      align-items: baseline;
      font-size: 2.8rem;
      font-weight: bold;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .card-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 75;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    & + & {
      margin-top: 0;
    }

    &:first-child {
      border-radius: 1rem 0 0 1rem;
    }

    &:nth-child(2) {
      border-radius: 0 0 0 0;
    }

    &:last-child {
      border-radius: 0 1rem 1rem 0;
    }
  `}
`

function Card({
  background_image,
  children,
  emphasize,
  content_section_id,
  ...props
}) {
  const { _meta, title } = props.content
  const type = _meta.type
  const url = type === "page" ? linkResolver(_meta) : null

  return (
    <StyledCard className={`${type === "page" ? "-link" : ""}`} {...props}>
      {type === "product" && <Product product={props.content} />}
      {type === "page" && (
        <div className="content">
          <span className="title">
            {title[0].text} -{" "}
            <span className="section">{content_section_id}</span>
          </span>
          <span className="read-more">
            <span>Læs mere </span>
            <Icon icon="chevron-right" size="sm" />
          </span>
        </div>
      )}
      {type === "page" && (
        <Link to={`${url}#${content_section_id}`} className="card-link" />
      )}
      {background_image && (
        <div className="bg-img">
          <ResponsiveImage img={background_image} />
        </div>
      )}
    </StyledCard>
  )
}

export default Card

Card.defaultProps = {}
Card.propTypes = {
  children: PropTypes.node.isRequired,
}
