import React from "react"
import { graphql } from "gatsby"
import { Elements } from "prismic-reactjs"
import { valuable } from "utils/worthy"
import styled from "styled-components"
import Content from "components/Content"
import ProductPrice from "./Price"

const StyledProduct = styled.div`
  ${(props) => props.theme.media.tablet_landscape_up`

  `}
`

const StyledProductPrice = styled(ProductPrice)``

const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.heading1:
      return <h3 key={key}>{children}</h3>
    default:
      return null
  }
}

function Product({ product, ...props }) {
  const {
    title,
    description,
    currency,
    current_price,
    product_color,
    text_after_price,
    text_before_price,
  } = product
  return (
    <StyledProduct
      size="sm"
      style={{ borderTop: `5px solid ${product_color}` }}
    >
      {valuable(title) && (
        <Content content={title} serializer={htmlSerializer} />
      )}
      {valuable(description) && <Content content={description} />}
      {current_price && currency && (
        <StyledProductPrice
          text_before_price={text_before_price}
          text_after_price={text_after_price}
          currency={currency}
          current_price={current_price}
          product_color={product_color}
        />
      )}
    </StyledProduct>
  )
}

export default Product

Product.defaultProps = {}
Product.propTypes = {}

/* export const query = graphql`
  fragment ProductFragment on PRISMIC_Product {
    title
    description
    currency
    current_price
    product_color
    text_after_price
    text_before_price
    _meta {
      type
    }
  }
`
 */
