import React from "react"
import PropTypes from "prop-types"
import HeroSection from "./HeroSection"
import Paragraph from "./Paragraph"
import ParagraphImage from "./ParagraphImage"
import PersonCTA from "./Person/CTA"
import PersonQuote from "./Person/Quote"
import CardRepeater from "./Card/Repeater"
import PersonRepeater from "./Person/Repeater"

function SliceZone({ ...props }) {
  const { isHome, slices, pageInfo } = props
  if (!slices || slices === null) return null
  const slice = slices.map((s, index) => {
    switch (s.type) {
      case "hero_section":
        return (
          <HeroSection
            key={`${s.type}_${index}`}
            ctas={s.fields}
            isHome={isHome}
            {...s.primary}
          />
        )
      case "paragraph":
        return <Paragraph key={`${s.type}_${index}`} {...s.primary} />
      case "paragraph___image":
        return <ParagraphImage key={`${s.type}_${index}`} {...s.primary} />
      case "person_cta":
        return (
          <PersonCTA
            key={`${s.type}_${index}`}
            ctas={s.fields}
            {...s.primary}
          />
        )
      case "card_repeater":
        return (
          <CardRepeater
            key={`${s.type}_${index}`}
            items={s.fields}
            {...s.primary}
          />
        )
      case "person_repeater":
        return (
          <PersonRepeater
            key={`${s.type}_${index}`}
            items={s.fields}
            {...s.primary}
          />
        )
      case "person_quote":
        return <PersonQuote key={`${s.type}_${index}`} {...s.primary} />
      default:
        console.warn(`No support for slice type ${s.type}`)
        return null
    }
  })
  return <>{slice}</>
}

export default SliceZone

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}
