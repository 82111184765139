import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ViewportContext } from "context"
import { isViewport } from "helpers"
import Image from "components/Image"

const StyledImg = styled.img`
  width: 100%;
  object-fit: cover;
  line-height: 0;
`

function ResponsiveImage({ img, alt, ...props }) {
  const viewport = useContext(ViewportContext)
  let imgSrc

  if (img && img.hasOwnProperty("childImageSharp")) {
    imgSrc = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
      ? img.childImageSharp.mobile
      : img.childImageSharp.desktop
    return <Image fixed={imgSrc} alt={alt} {...props} />
  } else if (img) {
    imgSrc = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"])
      ? img.hasOwnProperty("mobile")
        ? img.mobile.url
        : img.url
      : img.url
    return <StyledImg src={imgSrc} alt={alt} {...props} />
  }
  return null
}

export default ResponsiveImage

ResponsiveImage.defaultProps = {
  alt: "",
}
ResponsiveImage.propTypes = {
  img: PropTypes.object,
  alt: PropTypes.string,
}
