import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import CTA from "./"

const StyledCTARepeater = styled.div``

function CTARepeater({ items, ...props }) {
  if (!items) return null
  if (items.length === 0 || (items.length === 1 && items[0].cta_link === null))
    return null
  return (
    <StyledCTARepeater position="left" {...props}>
      {items.map((cta, i) => (
        <CTA key={cta.cta_link._meta.id} {...cta} />
      ))}
    </StyledCTARepeater>
  )
}

export default CTARepeater

CTARepeater.defaultProps = {}
CTARepeater.propTypes = {}
