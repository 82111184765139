import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import ResponsiveImage from "components/ResponsiveImage"
import Content from "components/Content"
import {
  bgBrandShape,
  bgBrandShapeMobile,
  clipImage,
  clipImageMobile,
} from "components/Shape"

const StyledPerson = styled.article`
  .image {
    overflow: hidden;
    border-radius: 1rem 0 0 1rem;
    ${clipImage(true)}
  }

  & + & {
    .image {
      border-radius: 0 1rem 1rem 0;
      ${clipImage()}
    }
  }

  .content {
    padding: 1rem 2rem;
  }
`

const StyledResponsiveImage = styled(ResponsiveImage)``

function Person({
  image,
  first_name,
  last_name,
  role,
  areas_of_work,
  email,
  phone,
  bio,
  social_media_links,
  children,
  ...props
}) {
  return (
    <StyledPerson {...props}>
      <div className="image">
        <StyledResponsiveImage img={image} />
      </div>
      <div className="content">
        {first_name && (
          <>
            {first_name && last_name && (
              <h3 className="name">{`${first_name} ${last_name}`}</h3>
            )}
            {role && <p className="role">{role}</p>}
            {areas_of_work && <p className="work">{areas_of_work}</p>}
            {bio && <Content content={bio} />}
            {children}
          </>
        )}
      </div>
    </StyledPerson>
  )
}

export default Person

Person.defaultProps = {}
Person.propTypes = {}

export const query = graphql`
  fragment PersonFragment on PRISMIC_Person {
    image
    imageSharp @include(if: $isProduction) {
      childImageSharp {
        id
        desktop: fixed(width: 480) {
          ...GatsbyImageSharpFixed
        }
        mobile: fixed(width: 480) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    first_name
    email
    bio
    areas_of_work
    last_name
    phone
    role
    social_media_links {
      icon
      social_media_profile_url {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
      social_media_site
    }
  }
`
