import { css } from "styled-components"

const bgBrandShape = (bg, color, reverse) => {
  return css`
    position: relative;
    color: ${color};

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${bg};
      display: block;
      z-index: 1000;
      content: "";
      clip-path: ${
        reverse
          ? `polygon(0 0, 100% 0%, 100% 100%, 14% 100%);`
          : `polygon(0 0, 100% 0%, 86% 100%, 0% 100%);`
      }
    }
  `
}

const bgBrandShapeMobile = (bg, color, reverse) => {
  return css`
    position: relative;
    color: ${color};

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${bg};
      display: block;
      z-index: 1000;
      content: "";
      clip-path: ${reverse
        ? `polygon(0 0, 100% 10%, 100% 100%, 0 100%);`
        : `polygon(0 0, 100% 0%, 100% 90%, 0 100%);`};
    }
  `
}

const clipImage = (reverse) => {
  return reverse
    ? css`
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
      `
    : css`
        clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
      `
}

const clipImageMobile = (reverse) => {
  return reverse
    ? css`
        clip-path: polygon(0 4%, 100% 0, 100% 100%, 0 100%);
      `
    : css`
        clip-path: polygon(0 0, 100% 0%, 100% 90%, 0 100%);
      `
}

export { bgBrandShape, clipImage, bgBrandShapeMobile, clipImageMobile }
