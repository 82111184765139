import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { default as GridParagraph } from "@mostlycode/ui/dist/Paragraphs/GridParagraph"
import { Elements } from "prismic-reactjs"
import Content from "components/Content"
import Container from "components/Container"
import ResponsiveImage from "components/ResponsiveImage"
import {
  bgBrandShape,
  bgBrandShapeMobile,
  clipImage,
  clipImageMobile,
} from "components/Shape"

const StyledParagraphImage = styled(GridParagraph)`
  padding: 4rem 0 2rem;

  .content {
    grid-area: paragraph;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.kelp};
    padding: 4rem 8rem 4rem 4rem;

    ${(props) => props.theme.media.tablet_landscape_up`
      position: relative;
      z-index: 200;
      padding: 0;

      .inner {
        p {
          font-size: 2.1rem;
        }
      }
    `}
  }

  .image {
    grid-area: image;
    overflow: hidden;
    ${(props) => props.theme.media.tablet_landscape_up`
      ${
        !props.positionRight
          ? `margin-right: 4rem; border-radius: 1rem 0 0 1rem;`
          : `margin-left: 4rem; border-radius: 0 1rem 1rem 0;`
      }
      ${clipImage(!props.positionRight)}
      position: relative;
      z-index: 100;

    `}
  }
`

const StyledResponsiveImage = styled(ResponsiveImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;

  ${(props) => props.theme.media.tablet_landscape_up`
  `}
`

function ParagraphImage({
  image,
  content,
  image_position,
  section_id,
  ...props
}) {
  const gridPhone = `"image" "paragraph"`
  const gridTabletPortrait = image_position
    ? `"paragraph paragraph paragraph paragraph image image image image"`
    : `"image image image image paragraph paragraph paragraph paragraph"`

  return (
    <Container className="container">
      <StyledParagraphImage
        id={section_id}
        gridPhone={gridPhone}
        gridTabletPortrait={gridTabletPortrait}
        positionRight={image_position}
        useFractions
        {...props}
      >
        <div className="content">
          <Content content={content} />
        </div>
        {image && (
          <div className="image">
            <StyledResponsiveImage img={image} />
          </div>
        )}
      </StyledParagraphImage>
    </Container>
  )
}

export default ParagraphImage

ParagraphImage.defaultProps = {}
ParagraphImage.propTypes = {
  content: PropTypes.array.isRequired,
  image: PropTypes.shape({
    fixed: PropTypes.object,
    fluid: PropTypes.object,
  }),
}
