import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon } from "@mostlycode/ui"
import Container from "components/Container"
import Content from "components/Content"
import ResponsiveImage from "components/ResponsiveImage"
import { clipImage } from "components/Shape"

const StyledPersonQuote = styled.div`
  background: ${(props) => props.theme.colors.beige};
  padding: 4rem 0;
  .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 42rem;
    overflow: hidden;
  }

  .container {
    padding: 0;
    margin: 0;
  }

  .quote {
    padding: 2rem;
    margin: 2rem;
    background: ${(props) => props.theme.colors.beige};

    p {
      font-size: 2.1rem;
      line-height: 1.4;

      strong {
        display: block;
        font-size: 2.7rem;
        margin: 2rem 0;
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    .container {
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 0 2rem;

      ${!!props.positionRight ? `` : `flex-direction: row-reverse;`}
    }

    .quote {
      flex: 1 1 60%;
    }

    .image {
      flex: 1 1 34%;
      width: 42rem;

      ${
        props.positionRight
          ? `margin-left: 4rem; border-radius: 0 1rem 1rem 0;`
          : `margin-right: 4rem; border-radius: 1rem 0 0 1rem;`
      }

      ${clipImage(!props.positionRight)}
    }

    .quote {
      padding: 4rem 0;
      margin: 0;
      background: none;
    }
  `}
`

function PersonQuote({
  children,
  quote,
  person,
  person_position,
  person_quote_image,
  person_quote_imageSharp,
  ...props
}) {
  console.log(props)
  return (
    <StyledPersonQuote positionRight={person_position} {...props}>
      <Container className="container">
        <blockquote className="quote">
          <Content content={quote} />
        </blockquote>
        <div className="image">
          <ResponsiveImage
            img={person_quote_imageSharp || person_quote_image}
          />
        </div>
      </Container>
    </StyledPersonQuote>
  )
}

export default PersonQuote

PersonQuote.defaultProps = {}
PersonQuote.propTypes = {
  children: PropTypes.node,
}
