import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"

const StyledLink = styled.a`
  display: block;
  padding: 1rem;
  background: ${(props) => props.theme.colors.ocher};
  text-align: center;
  font-weight: bold;

  &:first-of-type {
    margin-top: 2rem;
    color: ${(props) => props.theme.colors.white};
  }

  & + & {
    margin-top: 1rem;
    background: transparent;
    color: ${(props) => props.theme.colors.ocher};
  }

  &:focus {
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: inline-block;
    padding: 1rem 3rem;
    & + & {

    }
  `}
`

const StyledLinkInner = styled.span``

function Link({ children, blank, inline, to, forwardAs, ...props }) {
  if (blank)
    return (
      <StyledLink
        href={to}
        target="_blank"
        rel="noreferrer noopener"
        {...props}
      >
        <StyledLinkInner inline={inline}>{children}</StyledLinkInner>
      </StyledLink>
    )
  return (
    <StyledLink to={to} as={forwardAs} {...props}>
      <StyledLinkInner inline={inline}>{children}</StyledLinkInner>
    </StyledLink>
  )
}

export default Link

Link.defaultProps = {
  blank: null,
  inline: null,
  forwardAs: GatsbyLink,
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  blank: PropTypes.bool,
  inline: PropTypes.bool,
}
