const scrollElementIntoView = (event, id) => {
  if (typeof window !== `undefined`) {
    event.preventDefault()
    const elm = document.querySelector(id)
    elm.scrollIntoView({ behavior: "smooth" })
  }
  return
}

export { scrollElementIntoView }
