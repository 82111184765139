import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Link from "./Link"
import { linkResolver } from "utils/linkResolver"
import { scrollElementIntoView } from "./helper"

const StyledCTA = styled(Link)``

function CTA({ cta_link, cta_link_text, ...props }) {
  const link = linkResolver(cta_link._meta)
  if (cta_link._meta.type === "lead_module") {
    return (
      <StyledCTA
        href={link}
        forwardAs="a"
        onClick={(e) => scrollElementIntoView(e, link)}
        {...props}
      >
        {cta_link_text}
      </StyledCTA>
    )
  }
  return (
    <StyledCTA to={link} {...props}>
      {cta_link_text}
    </StyledCTA>
  )
}

export default CTA

CTA.defaultProps = {}
CTA.propTypes = {
  children: PropTypes.node,
}
