import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { isEmpty } from "utils/worthy"

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;

  img {
    object-fit: contain;
  }
`

function Image({ ...props }) {
  return isEmpty(props, ["fluid", "fixed"]) ? null : <StyledImg {...props} />
}

export default Image
