import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { formatCurrency } from "@mostlycode/utils"

const StyledProductPrice = styled.span`
  .prepend {
  }

  .append {
  }

  .price {
  }
`

function ProductPrice({
  text_before_price,
  text_after_price,
  current_price,
  currency,
  children,
  ...props
}) {
  const current_price_formatted = formatCurrency(
    current_price / 100,
    currency,
    "da-dk",
    0
  )
  return (
    <StyledProductPrice {...props}>
      <span className="price">
        <span className="prepend">{text_before_price}</span>
        {current_price_formatted}
        <small className="append">{text_after_price}</small>
      </span>
    </StyledProductPrice>
  )
}

export default ProductPrice

ProductPrice.defaultProps = {}
ProductPrice.propTypes = {
  children: PropTypes.node,
}
