import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Icon } from "@mostlycode/ui"
import Content from "components/Content"
import Person from "./"
import { getIconFromUrl } from "utils/iconResolver"

const StyledPersonRepeater = styled.section`
  max-width: 120rem;
  margin: 0 auto;
  padding: 2rem;
  h2 {
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    margin: auto;
  }
`

const StyledPerson = styled(Person)`
  .headshot {
  }

  .details {
    display: flex;
    flex-direction: column;
  }
`

function PersonRepeater({ persons_content, persons_title, items, ...props }) {
  return (
    <StyledPersonRepeater>
      <Content content={persons_title} />
      <Content content={persons_content} />
      <div className="grid">
        {items.map((item) => {
          const { person } = item
          return (
            person && (
              <StyledPerson {...person}>
                <div className="details">
                  {person.phone && (
                    <a href={person.phone}>
                      {person.phone}
                      <Icon
                        icon="phone-alt"
                        style={{ transform: "scaleX(-1) rotate(15deg)" }}
                      />
                    </a>
                  )}
                  {person.email && (
                    <a href={`mailto:${person.email}`}>
                      {person.email}
                      <Icon icon="envelope" />
                    </a>
                  )}
                  {person.social_media_links.map((soMe) => {
                    return (
                      <a
                        href={soMe.social_media_profile_url.url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {soMe.social_media_site}{" "}
                        <Icon icon={getIconFromUrl(soMe.icon.url)} />
                      </a>
                    )
                  })}
                </div>
              </StyledPerson>
            )
          )
        })}
      </div>
    </StyledPersonRepeater>
  )
}

export default PersonRepeater

PersonRepeater.defaultProps = {}
PersonRepeater.propTypes = {}
