import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Card from "./"
import Content from "components/Content"
import Container from "components/Container"

const StyledCardRepeater = styled.section`
  padding: 2rem 4rem 4rem;

  .cards {
    display: grid;
    grid-row-gap: 2rem;
    padding: 4rem 0;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 6rem 4rem 8rem;

    .cards {
      display: grid;
      grid-template-columns: repeat(${props.columns}, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 0;
    }

    .inner {
      max-width: 60rem;
      text-align: center;
      margin: 0 auto;
    }
  `}
`

function CardRepeater({
  card_repeater_title,
  card_repeater_content,
  card_repeater_content_after,
  columns,
  items,
  children,
  ...props
}) {
  return (
    <StyledCardRepeater columns={columns} {...props}>
      <Container>
        <div className="inner">
          <Content content={card_repeater_title} />
          <Content content={card_repeater_content} />
        </div>
        <div className="cards">
          {items.map((item, i) => {
            return <Card key={`card_${i}`} {...item} />
          })}
        </div>
        <div className="inner">
          <Content content={card_repeater_content_after} />
        </div>
      </Container>
    </StyledCardRepeater>
  )
}

export default CardRepeater

CardRepeater.defaultProps = {}
CardRepeater.propTypes = {
  children: PropTypes.node,
}
